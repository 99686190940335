import axios from "axios";

const baseURL = process.env.REACT_APP_SERVICE_BASE_URL;

const useAxios = () => {
  const axiosInstance = axios.create({
    baseURL,
  });
  return axiosInstance;
};

export default useAxios;
