import React, { useState } from "react";
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { Box, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAxios from "../hooks/useAxios";
import { useMutation, useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { withAlert } from "../recoil/snackbar";
import { calculateAge } from "../utils/calculateAge";
import { DatePicker } from "antd";

const Register = () => {
  const openAlert = useSetRecoilState(withAlert);
  const [data, setData] = useState({
    start_time: null,
    completion_time: null,
    name: "",
    address: "",
    phone_number: "",
    email: "",
    child_age: "",
    child_name: "",
    child_birthday: "",
    current_milk: "",
    where_u_know: "",
    source: "",
    term_check: false,
    partner_name: "",
  });

  const api = useAxios();
  const [currentTime, setCurrentTime] = useState(null);
  const [completionTime, setCompletionTime] = useState("");
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);

  const radioOptions = [
    {
      value: "ဆိုင်ရှင်များ",
      label: "ဆိုင်ရှင်များ",
      textLabel: "Shop Name / ဆိုင်အမည်",
    },
    {
      value: "အရောင်းမြှင့်တင်ရေးဝန်ထမ်းများ (Promoters)",
      label: "အရောင်းမြှင့်တင်ရေးဝန်ထမ်းများ (Promoters)",
      textLabel: "Shop Name / ဆိုင်အမည်",
    },
    {
      value: "ပရိုမိုးရှင်းပွဲများ (Events)",
      label: "ပရိုမိုးရှင်းပွဲများ (Events)",
      textLabel: "Event Location / ပွဲနေရာ",
    },
    { value: "Other", label: "အခြား (Other)", textLabel: "အခြား (Other)" },
  ];

  const widthMatches = useMediaQuery("(min-width:840px)");

  const { data: response, mutate } = useMutation(
    (data) => {
      return api.post(
        "/api/customer",
        {
          ...data,
          email: data?.email.length >= 1 ? data?.email : "Anonymous",
          partner_name: pathname.slice(1),
          completion_time: completionTime,
          child_age: calculateAge(data?.child_birthday),
        },
        {
          validateStatus: function (status) {
            return status <= 500;
          },
        }
      );
    },
    {
      onSuccess: (res) => {
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
          if (res.status === 200) {
            window.location.replace("https://t.me/DugroMayMay");
          }
        }, 5000);
      },
    }
  );

  const onHandleSubmit = (e) => {
    e.preventDefault();
    handlePhoneNumber();
  };

  const handleOnChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
      start_time: currentTime,
    });
  };

  const handlePhoneNumber = (e) => {
    const phoneNumberRegex = /^09\d{0,9}$/;
    if (
      !(data?.phone_number === "" || phoneNumberRegex.test(data?.phone_number))
    ) {
      openAlert({
        status: 400,
        detail: "Invalid Phone number",
      });
    } else {
      mutate(data);
    }
  };

  const onPhoneNumberChange = (e) => {
    setData({
      ...data,
      phone_number: e.target.value.replace(/\D/g, ""),
    });
  };

  const getCurrentTime = async () => {
    const res = await api.get("api/customer/current-time");
    setCurrentTime(res?.data?.currentTime);
  };

  useQuery("get-current-time", getCurrentTime);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        position: "relative",
        backgroundImage:
          "linear-gradient(to top, #4cc35b, #61d26c, #74e17d, #87f08e, #9affa0)",
      }}
    >
      <Paper
        elevation={2}
        sx={{
          width: { xs: "90%", md: "80%" },
          zIndex: 2,
          backgroundColor: "secondary.ultraLight",
          borderRadius: "10px",
          margin: "1rem 0",
          overflow: "hidden",
          paddingBottom: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: { xs: "20px", md: "40px" },
          }}
        >
          <Box
            sx={{
              backgroundColor: "secondary.dark",
              padding: widthMatches ? "2rem " : "1rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <img
                src="/assets/logo.png"
                alt="Logo"
                style={{
                  width: "7rem",
                  objectFit: "fill",
                }}
              />
              <Typography
                variant="h6"
                sx={{
                  color: "secondary.ultraLight",
                  fontWeight: 700,
                  fontSize: { xs: 20, md: 25 },
                  marginTop: "1rem",
                  textAlign: widthMatches ? "center" : "left",
                }}
              >
                Dugro May May Club Membership Form
              </Typography>
            </Box>
            <Typography
              sx={{
                color: "secondary.ultraLight",
                width: { xs: "100%" },
                marginTop: "1rem",
                textAlign: "left",
                fontSize: 14,
              }}
            >
              Dugro May May Club ရဲ့ အထူးပရိုမိုးရှင်းများ၊ ပွဲများ၊ သင်တန်းများ
              အစရှိသော အထူးခံစားခွင့်အစီအစဥ်များတွင် ပါဝင်နိုင်ရန်အတွက် Dugro
              May May Club မှ ပြန်လည်ဆက်သွယ်အကြောင်းကြားနိုင်ဖို့ Membership
              Form လေးကို ဖြည့်သွင်းပေးခဲ့ကြပါဦးနော်။ Dugro May May Club သည်
              အသက် (၂)နှစ်အထက်ကလေးများ၏ မေမေများအတွက်သာ ဖြစ်ပါသည်။
            </Typography>
          </Box>

          <Box
            component="form"
            sx={{ width: "100%", padding: { xs: "0 1rem", md: "0 2rem" } }}
            onSubmit={onHandleSubmit}
          >
            <TextField
              onChange={handleOnChange}
              id="outlined-error-helper-text"
              label="Name / အမည်"
              value={data?.name}
              name="name"
              size={!widthMatches && "small"}
              fullWidth
              sx={{ marginY: "0.5rem" }}
              required={true}
              InputLabelProps={{
                style: {
                  color: "black",
                },
              }}
            />

            <TextField
              onChange={handleOnChange}
              label="Address / နေရပ်လိပ်စာ"
              name="address"
              size={!widthMatches && "small"}
              value={data?.address}
              fullWidth
              sx={{ marginY: "0.5rem" }}
              required={true}
              InputLabelProps={{
                style: {
                  color: "black",
                },
              }}
            />

            <TextField
              onChange={onPhoneNumberChange}
              label="Phone / ဖုန်းနံပါတ်"
              name="phone_number"
              size={!widthMatches && "small"}
              value={data?.phone_number}
              fullWidth
              sx={{ marginY: "0.5rem" }}
              required={true}
              placeholder="09123456789"
              InputProps={{
                inputProps: {
                  pattern: "[0-9]*",
                },
              }}
              InputLabelProps={{
                style: {
                  color: "black",
                },
              }}
            />

            <TextField
              onChange={handleOnChange}
              label="Email / အီးမေးလ်"
              name="email"
              size={!widthMatches && "small"}
              value={data?.email}
              fullWidth
              sx={{ marginY: "0.5rem" }}
              InputLabelProps={{
                style: {
                  color: "black",
                },
              }}
            />

            <TextField
              onChange={handleOnChange}
              label="Child Name / ကလေးအမည်"
              name="child_name"
              size={!widthMatches && "small"}
              value={data?.child_name}
              fullWidth
              sx={{ marginY: "0.5rem" }}
              required={true}
              InputLabelProps={{
                style: {
                  color: "black",
                },
              }}
            />

            <DatePicker
              format="MM/DD/YYYY"
              disabledDate={(current) => current > new Date()}
              style={{
                backgroundColor: "transparent",
                border: "1px solid rgba(0,0,0,0.2) ",
                padding: widthMatches ? "15px 12px" : "8px",
                borderRadius: "5px",
                width: "100%",
                margin: "0.5rem 0",
              }}
              onChange={(date, dateString) =>
                setData({
                  ...data,
                  child_birthday: dateString,
                })
              }
              placeholder="Child Birthday / ကလေးမွေးနေ့ *"
              size="large"
            />

            <TextField
              onChange={handleOnChange}
              label="လက်ရှိသုံးနေသောနို့မှုန့်"
              name="current_milk"
              size={!widthMatches && "small"}
              value={data?.current_milk}
              fullWidth
              sx={{ marginY: "0.5rem" }}
              required={true}
              InputLabelProps={{
                style: {
                  color: "black",
                },
              }}
            />

            <FormControl
              sx={{
                my: "1rem",
                border: "1px solid rgba(0,0,0,0.2) ",
                padding: "1rem",
                width: "100%",
                borderRadius: "5px",
              }}
            >
              <FormLabel htmlFor="where_u_know" sx={{ mb: "0.5rem",color:"black" }}>
                Dugro May May Club ကို မည်သည့်နေရာမှ သိရှိခဲ့သနည်း?
              </FormLabel>
              <RadioGroup id="where_u_know" name="radio-buttons-group">
                <FormControlLabel
                  value="facebook"
                  onChange={(e) =>
                    setData(() => {
                      return {
                        ...data,
                        where_u_know: e.target.value,
                        source: "facebook",
                      };
                    })
                  }
                  control={<Radio size="medium" />}
                  label={
                    <Box
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Typography>
                            Facebook <span>*</span>{" "}
                          </Typography>
                        </Box>
                  }
                />
                {radioOptions.map((option) => (
                  <Box key={option.value}>
                    <FormControlLabel
                      required
                      value={option.value}
                      control={<Radio size="medium" />}
                      label={
                        <Box
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Typography>
                            {option.label} <span>*</span>{" "}
                          </Typography>
                        </Box>
                      }
                      onChange={(e) =>
                        setData(() => ({
                          ...data,
                          where_u_know: e.target.value,
                          source: "",
                        }))
                      }
                    />
                    <TextField
                      onChange={handleOnChange}
                      label={option.textLabel}
                      name="source"
                      size={!widthMatches && "small"}
                      value={data?.source}
                      fullWidth
                      sx={{
                        marginY: "0.5rem",
                        display:
                          data.where_u_know === option.value ? "block" : "none",
                      }}
                      required
                      InputLabelProps={{
                        style: {
                          color: "black",
                        },
                      }}
                    />
                  </Box>
                ))}
              </RadioGroup>
            </FormControl>
            <Box sx={{ display: "flex", alignItems: "start", width: "100%" }}>
              <Checkbox
                sx={{ display: "inline-block", height: "30px", ml: "-0.5rem" }}
                name="term_check"
                value={data.term_check}
                checked={data.term_check}
                required={true}
                onChange={() => {
                  api
                    .get("api/customer/current-time")
                    .then((res) => setCompletionTime(res?.data?.currentTime));

                  setData({ ...data, term_check: !data.term_check });
                }}
              />

              <span
                style={{
                  width: "100%",
                  marginTop: "6px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                ကျွန်ုပ်သည် မိမိ၏သဘောဆန္ဒအလျှောက် Dugro May May Club Member
                အဖြစ်ဝင်ရောက်ခြင်း ဖြစ်ပါသည်။ Dugro May May Club မှ မိမိအား
                ဆက်သွယ်ခြင်းကို ခွင့်ပြုပြီး မိမိကိုယ်ရေးကိုယ်တာအချက်အလက်များကို
                စုဆောင်းဆောင်ရွက် ခွင့်ပြုပါသည်။
              </span>
            </Box>

            <LoadingButton
              type="submit"
              variant="contained"
              disableElevation
              sx={{
                width: "100%",
                fontWeight: 700,
                margin: "0 auto",
                marginY: "0.5rem",
                color: "white",
                marginTop: "1rem",
              }}
            >
              Register
            </LoadingButton>
          </Box>
        </Box>
      </Paper>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {data?.child_birthday ? (
          response?.status === 200 ? (
            <>
              <DialogTitle
                sx={{ backgroundColor: "primary.main", color: "white" }}
                id="alert-dialog-title"
              >
                {"ကျေးဇူးတင်ပါသည်။"}
              </DialogTitle>
              <DialogContent sx={{ backgroundColor: "primary.main" }}>
                <DialogContentText
                  sx={{ color: "white" }}
                  id="alert-dialog-description"
                >
                  စာရင်းသွင်းမှုအောင်မြင်ပါသည်။ Telegram Group ထဲမှာ Join
                  ကိုနှိပ်ပြီး Dugro May May Club Member ဝင်တစ်ဦး ဖြစ်လိုက်ပါ။
                </DialogContentText>
              </DialogContent>
            </>
          ) : (
            response?.data?.message === "You are already member" && (
              <>
                <DialogContent sx={{ backgroundColor: "primary.main" }}>
                  <DialogContentText
                    sx={{ color: "white", fontSize: 18 }}
                    id="alert-dialog-description"
                  >
                    Dugro May May Club Member ဝင်ထားပြီးသားဖြစ်ပါသည်။
                    ကျေးဇူးတင်ပါသည်။
                  </DialogContentText>
                </DialogContent>
              </>
            )
          )
        ) : (
          <DialogContent sx={{ backgroundColor: "secondary.main" }}>
            <DialogContentText
              sx={{ color: "white", fontSize: 18 }}
              id="alert-dialog-description"
            >
              ကျေးဇူးပြု၍ ကလေးမွေးနေ့အကွက်ကို ဖြည့်ပေးပါ။
            </DialogContentText>
          </DialogContent>
        )}
      </Dialog>
    </Box>
  );
};

export default Register;
