import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    // use for mui components like Button and input
    primary: {
      main: "#45ac34",
      light: "#dc3545",
      ultraLight: "#9affa0",
      dark: "#dc3545",
      gradient: "linear-gradient(to top, #4cc35b, #61d26c, #74e17d, #87f08e, #9affa0)"
    },
    // use for typo color and line
    secondary: {
      main: "#dc3545",
      light: "#45ac34",
      ultraLight: "#e1faf9",
      dark: "#45AC34"
    },
  },
});
