import { QueryClient, QueryClientProvider } from "react-query";
import Register from "./containers/Register";
import { CustomSnackbar } from "./hocs/CustomSnackbar";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import GlobalStyles from "./utils/GlobalStyles";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <CustomSnackbar>
        <GlobalStyles />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Navigate to={"/dugro"} />} />
            <Route path="/dugro" element={<Register />} />
            <Route path="/facebook" element={<Register />} />
            <Route path="/offline" element={<Register />} />
            <Route path="/hellosayarwon" element={<Register />} />
            <Route path="*" element={<Navigate to={"/"} />} />
          </Routes>
        </BrowserRouter>
      </CustomSnackbar>
    </QueryClientProvider>
  );
}

export default App;
