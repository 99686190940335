import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  /* Global styles go here */
  ::placeholder {
      color: black !important;
    }
  
    ${
      "" /* .css-1x2jons-MuiFormControlLabel-asterisk {
      display: none !important;
    } */
    }

    .MuiFormControlLabel-asterisk {
      display: none !important;
    }
`;

export default GlobalStyles;
