export const calculateAge = (birthdate) => {
  const birthDate = new Date(birthdate);
  const currentDate = new Date();

  const yearsDiff = currentDate.getFullYear() - birthDate.getFullYear();
  const monthsDiff = currentDate.getMonth() - birthDate.getMonth();

  let years = yearsDiff;
  let months = monthsDiff;

  if (monthsDiff < 0) {
    years -= 1;
    months += 12;
  }

  if (years > 1) {
    if (months > 1) return `${years} years and ${months} months`;
    return `${years} years and ${months} month`;
  } else if (years === 0) {
    if (months > 1) return `${months} months`;
    return `${months} month`;
  } else if (years === 1) {
    if (months > 1) return `${years} year and ${months} months`;
    return `${years} year and ${months} month`;
  }
};
